import React from 'react';
import Layout, { Main } from '../components/Layout';
import { Content } from '../components/Content';
import { Text } from '../components/Text';

export const NotFoundPageTemplate = () => (
  <>
    <Content restrictWidth={false} className="content" disableSubtitlePadding>
      <Text type="title3" tag="h4" color="white_1">
        404: NOT FOUND
      </Text>
      <Text type="body1" tag="p" color="white_1">
        You just hit a route that doesn&#39;t exist... the sadness.
      </Text>
    </Content>
  </>
);

const NotFoundPage = () => {
  return (
    <Layout hasRightContent location={{ pathname: `/404` }}>
      <Main background="transparent" disableVertPadding={false}>
        <NotFoundPageTemplate />
      </Main>
    </Layout>
  );
};

export default NotFoundPage;
